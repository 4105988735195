.sort {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  color: var(--color-grey-secondary);
  margin-right: 2.5rem;
  margin-top: 6rem;
  font-size: var(--font-size-small);
}

.sortDisclosure {
  gap: 3px;
}

.optionsWrapper {
  display: flex;
  flex-direction: column;
  margin-top: 1.5rem;
}

.sort.open .optionsWrapper {
  cursor: pointer;
}

.option {
  font-size: 11px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--color-grey-secondary);
  padding: 0.3em 0.6em 0.3em 1em;
  border: 1px solid var(--color-grey-secondary);
  transition: color, border-color 0.25s;
}

.option {
  visibility: hidden;
}

.sort.open .option {
  visibility: visible;
}

.option .label {
  margin-right: 2px;
  transition: color 0.5s;
}

.option.selected {
  border-color: var(--color-white);
}
.option:not(.selected) {
  transition: background-color 0.5s;
}

.option:hover:not(.selected) {
  background-color: var(--color-black-secondary);
}

.icon {
  transform: scale(0.6);
  transition: color, transform 0.25s ease-out;
}

.icon path {
  color: var(--color-grey);
  transition: color 0.5s;
  transition-delay: 0.25;
}

.option.selected .label,
.option.selected:hover .icon path {
  color: var(--color-white);
}

.option.selected:hover .label {
  color: var(--color-grey-secondary);
}

.iconReverse {
  transform: rotate(180deg) scale(0.6);
}

.Chavron {
  transform: rotate(180deg) scale(0.65);
  margin-top: 3px;
  transition: transform 0.25s ease-out;
}

.Chavron.open {
  transform: rotate(0deg) scale(0.65);
  margin-top: 3px;
}

@media (max-width: 1350px) {
  .sort {
    margin: 0;
    justify-content: center;
    align-items: center;
  }

  .sort.open {
    margin-bottom: 4rem;
  }
}
