.SkeletonList {
  position: unset;
  font-size: 1.4rem;
  list-style-type: none;
  display: flex;
  flex-direction: column;
  gap: 4.5rem;
  margin-top: 6.5rem;
}

.SkeletonElement {
  position: relative;
  overflow: hidden;
  background-color: #212121;
  border-radius: 2px;
}

.hidden {
  visibility: hidden;
}

.listElement {
  height: 20px;
}

.SkeletonElement.extraSmall {
  width: 105px;
}

.SkeletonElement.small {
  width: 150px;
}

.SkeletonElement.medium {
  width: 220px;
}

.SkeletonElement.large {
  width: 260px;
}

.SkeletonElement.extraLarge {
  width: 325px;
}

.SkeletonShimmerWrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.SkeletonShimmer {
  width: 50%;
  height: 100%;
  background: rgba(0, 0, 0, 0.1);
  transform: skewX(-50deg);
  box-shadow: 0 0 60px 60px rgba(0, 0, 0, 0.1);
  animation: loading 2s infinite;
}

@keyframes loading {
  0% {
    transform: translateX(-20vw);
  }
  50% {
    transform: translateX(3vw);
  }
  100% {
    transform: translateX(20vw);
  }
}

.skeletonText {
  display: flex;
  flex-direction: column;
  margin-top: 10.5rem;
  margin-bottom: 2.5rem;
}

.amount {
  position: relative;
  height: 24px;
  width: 280px;
}

.shortened {
  margin-top: 10px;
  height: 22px;
  width: 240px;
}

.lastChecked {
  position: relative;
  margin-top: 7px;
  height: 19px;
  width: 250px;
}

@media (max-width: 1350px) {
  .SkeletonList {
    justify-content: center;
    align-items: center;
    margin-top: 0;
    gap: 9.15rem;
  }

  .SkeletonElement.extraSmall,
  .SkeletonElement.small,
  .SkeletonElement.medium,
  .SkeletonElement.large,
  .SkeletonElement.extraLarge {
    width: 130px;
    height: 25px;
  }

  .skeletonText {
    margin-top: 4.7rem;
    align-items: center;
    margin-bottom: 48px;
  }

  .amount {
    width: 250px;
    height: 22px;
    margin-left: 2px;
  }

  .shortened {
    width: 220px;
    margin-top: 7px;
    height: 21px;
  }

  .lastChecked {
    height: 18px;
    width: 150px;
    margin-top: 10px;
  }
}
