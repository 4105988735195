.epContent {
  display: flex;
  flex-direction: column;
}

.tagsWrapper {
  display: flex;
  gap: 9px;
  margin-bottom: 8px;
}

.epName {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 0.75rem;
}

.epNumber {
  color: var(--color-orange);
}

.epGuest {
  text-align: center;
}

.timeDetail {
  font-size: var(--font-size-small);
  letter-spacing: 0.1px;
  color: var(--color-grey);
}

@media (max-width: 1350px) {
  .EpisodeItem {
    display: unset;
    text-align-last: center;
    align-items: unset;
    width: 100%;
  }

  .epContent {
    align-items: center;
  }

  .epName {
    justify-content: center;
    gap: 0.5rem;
  }

  .timeDetail {
    display: block;
    margin: 4px 0 0 0;
    font-size: var(--font-size-small);
  }
}
