.AmountInfo {
  font-size: 2rem;
}

.AmountInfoItem {
  display: block;
  white-space: nowrap;
}

.AmountInfoItem .count {
  color: var(--color-red);
  font-weight: 700;
  position: relative;
}

.AmountInfoItem .count:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 0;
  right: 0;
  bottom: 1px;
  border-bottom: 2px solid var(--color-red);
}
.AmountInfoItem .count.NoAmount:after {
  border-color: var(--color-green);
}

.LastChecked {
  font-size: 1.4rem;
  display: flex;
  text-align: center;
  margin-top: 1px;
  color: var(--color-grey);
  margin-bottom: 3rem;
}

.count.NoAmount {
  color: var(--color-green);
}

.Checkmark {
  margin: -1px 0 0 6px;
  color: var(--color-green);
}

@media (max-width: 1350px) {
  .AmountInfo {
    font-size: 1.8rem;
    margin: 4.5rem 0 1.75rem;
    text-align: center;
  }

  .AmountInfoItem {
    margin: 0 auto;
  }

  .AmountInfo .count:after {
    bottom: 0;
    width: 95%;
  }

  .LastChecked {
    justify-content: center;
    margin-left: 1.25rem;
    margin-top: 0.7rem;
  }

  .LastChecked time {
    display: none;
  }
}
